.tools-container {
  position: absolute;
  top: 0;
}

.tools-container button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* multipleobjectspopupcontent */
.multipleobjectspopupcontent {
  max-height: 30vh;
}
.multipleobjectspopupcontent-item {
  cursor: pointer;
  transition: .2s;
  border-radius: 4px;
  min-width: 25vw;
}
.multipleobjectspopupcontent-item:hover {
  background-color: #f2f2f2;
}
.multipleobjectspopupcontent-item-title {
}
.multipleobjectspopupcontent-item-body {
}
.multipleobjectspopupcontent-item-body small {
}
