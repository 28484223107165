.grey-background {
  background-color: #f2f2f2;
  border-top: 1px solid #d8d8d8;
}

.selected-row {
  background-color: #ffb612;
}

.selected-row:hover {
  background-color: #ffb612 !important;
}

.space-left {
  padding-left: 3rem !important;
}

table th {
  border-top: unset !important;
}