.vh-65 {
    height: 65vh !important;
}

.table {
    th, td {
        vertical-align: middle !important;
    }
    table-layout: fixed;
}

.fixHeader {
    overflow-y: auto;

}
.fixHeader thead th {
    position: sticky !important;
    top: 0;
}

.alert-enter {
    opacity: 0;
  }
  .alert-enter-active {
    opacity: 1;
    transition: opacity 250ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
  