body {
  min-height: unset !important;
}

.mastcontainer-map {
  height: 100vh;
}

.no-mastnav {
  .actionbar {
    width: 100%;
  }
  .mastcontainer {
    padding-left: 0;
  }
}

.loader {
  text-align: center;
}

.loader,
.login-form {
  margin-top: 50vh;
  transform: translateY(-50%);

  &.center {
    margin: inherit;
    transform: inherit;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.login-form .logo {
  max-height: 18rem;
}

.version {
  position: absolute;
  right: 0;
  font-size: 0.7rem;
}

/* Make cool transitions for fullscreen mode */
.mastheader,
.mastnav,
.mastcontainer,
.actionbar,
.table-filters,
.sideBar-container {
  transition: 0.4s ease-in-out;
}

.mastnav.fullscreen {
  left: -7rem !important;
}

.mastheader.fullscreen {
  top: -5rem !important;
}

.actionbar.fullscreen {
  top: 0 !important;
  width: 100% !important;
}

.mastcontainer.fullscreen {
  padding-top: 4.5rem !important;
  padding-left: 0 !important;
}

.table-filters.fullscreen {
  top: 5rem !important;
}

.sideBar-container.fullscreen {
  left: -6.5rem !important;
  top: -3.7rem !important;
  height: 109vh !important;
}

/* fullscreen switch */

.btn-fullscreen {
  position: absolute;
  bottom: 2rem;
  right: 0.5rem;
  font-size: 2rem !important;
}
.btn-resetviewport {
  position: absolute;
  bottom: 5rem;
  right: 0.5rem;
  font-size: 2rem !important;
}
.btn-resetview {
  position: absolute;
  bottom: 8rem;
  right: 0.5rem;
  font-size: 2rem !important;
}
.btn-sm {
  font-size: small !important;
}

/* media queries won't accept CSS variables... */

/* lg breakpoint 1024 px */
@media screen and (max-width: 1023px) {
  .btn-fullscreen {
    bottom: 6rem;
  }
  .btn-resetviewport {
    bottom: 9rem;
  }
}

@media screen and (max-width: 1023.98px) {
  .sideBar-container {
    padding-left: 0;
    padding-top: 0;
    bottom: 0;
    max-width: 100vw;
    width: 100vw;
    top: 30%;
  }
  .sideBar-content {
    max-width: 100vw;
  }
  .sideBar-container.fullscreen {
    left: 0 !important;
  }
}
.sideBar-container {
  padding-top: 8.75rem;
}

@media (max-width: 767.98px) {
  .sideBar-container {
    padding-top: 7.625rem;
  }
}
@media (min-width: 1024px) {
  .sideBar-container {
    padding-top: 8.125rem;
  }
}
@media (min-width: 1280px) {
  .sideBar-container {
    padding-top: 9.0625rem;
  }
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.creation-sidebar.fullscreen {
  left: -6.25rem !important;
  top: -4.5rem !important;
  transition-delay: 0.2s;
}

@media screen and (max-width: 1023.98px) {
  .creation-sidebar {
    padding-left: 0.5rem !important;
    padding-top: 17.8rem !important;
    transition-delay: 0.11s;
  }
  .creation-sidebar.fullscreen {
    transition-delay: 0.25s;
    top: -4.25rem !important;
    left: 0 !important;
  }
}

.sentry-error-embed-wrapper {
  padding-top: 7.5% !important;
  z-index: 1057 !important;
}

.navtabs-item a {
  padding-bottom: 0.25rem !important;
}

.form-control-sm {
  padding: 0.125rem 0.8rem !important;
  min-height: 1.475rem !important;
  font-size: small !important;
}

.sentry-error-embed {
  max-height: 100% !important;
}

.cell-fixed {
  width: 2rem !important;
  min-height: 0 !important;
}

.table-scroller {
  overflow-x: scroll !important;
}

.btn-only-icon {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.detailed-table {
  th,
  td {
    vertical-align: top !important;
  }
  table-layout: auto;
}

.select-add-placeholder {
  height: 1.475rem;
  padding-right: 0 !important;
}

.select-menu {
  padding-left: 1rem !important;
  padding-right: 0.5rem !important;
}

.mapboxgl-popup-container {
  max-height: 75vh;
  max-width: 50vw;
  overflow: auto;
}

.sideBar-container {
  width: 40%;
  height: 100vh;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  padding-left: 6.25rem;
  background-color: white;
  background-clip: content-box;
}

.title-color {
  background-color: #333;
  color: white;
  font-size: medium;
}

.sideBar-navbar {
  font-size: small;
  margin-bottom: -0.025rem;
}

.sideBarTools-container button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.sideBar-content {
  overflow: auto;
  height: 100vh;
}

.select-position {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-down%3C/title%3E%3Cpath class='a' d='M25,40.42,1.15,17a4.69,4.69,0,0,1,0-6.15A5.15,5.15,0,0,1,4.23,9.65a4.38,4.38,0,0,1,3.08,1.16L25,28.12,42.69,10.81a4.6,4.6,0,0,1,6.93.77,5.39,5.39,0,0,1-1.16,5.77Z'/%3E%3C/svg%3E"),
    linear-gradient(to left, #0088ce 1.125rem, #f2f2f2 1rem) !important;
  background-position: center right 0.4rem, top left;
}

.creation-sidebar {
  margin-top: 18.09rem;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 6.75rem;
  transition-delay: 0.14s;
}

.creation-sidebar button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.select-sidebar {
  background-size: 0.5rem, 100% !important;
  padding-left: 0.5rem !important;
}

.btn-sm-sidebar {
  padding: 0.125rem 0 !important;
  font-size: x-small !important;
}

.small-cell-sidebar {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.select-add-append {
  min-height: 1.475rem !important;
  border-left-width: 5px !important;
  border-right-width: 5px !important;
}
